import gql from 'graphql-tag';

export const REQUEST_RESET = gql`
    mutation requestPasswordResetEmail($email: String!) {
        requestPasswordResetEmail(email: $email)
    }
`

export const PASSWORD_RESET = gql`
    mutation resetPassword(
        $email: String!
        $resetPasswordToken: String!
        $newPassword: String!
    ) {
        resetPassword(
            email: $email
            resetPasswordToken: $resetPasswordToken
            newPassword: $newPassword
        )
    }
`

export const GET_EMAIL_FROM_TOKEN = gql`
    query getEmailForToken($id: Int, $resetPasswordToken:String!){
        getEmailForToken(
            id: $id,
            resetPasswordToken:$resetPasswordToken
        )
    }
`