import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import FormInput from "../../../components/UI/FormInput/FormInput";
import {useForm} from "react-hook-form";
import {useMutation, useQuery} from "@apollo/react-hooks";
import ReCAPTCHA from 'react-google-recaptcha';
import {useHistory} from "react-router-dom";
import { I18n, I18nContext, T } from '@deity-io/falcon-i18n';
import {PASSWORD_RESET, GET_EMAIL_FROM_TOKEN} from "./queriesPasswordReset";
import { GET_BACKEND_CONFIG } from '../../../commonQueries';
import { usePopup } from '../../../context/PopupContext';
import './ResetPassword.scss';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';


const ResetPassword = () => {
    const recaptchaRef = useRef();
    const history = useHistory()
    const { t } = useContext(I18nContext);
    const { showAddToCartPopupMessage } = usePopup();
    const {register, handleSubmit, errors, getValues} = useForm();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');
    const token = queryParams.get('token');


    const { data: configData } = useQuery(GET_BACKEND_CONFIG);
    const [resetPassword] = useMutation(PASSWORD_RESET)

    const {data} = useQuery(GET_EMAIL_FROM_TOKEN, {
        variables:{
            id: parseInt(id),
            resetPasswordToken: token
        }
    })


    const onSubmit = async (submitData) => {
        const {password} = submitData;
        const recaptchaToken = await recaptchaRef.current.executeAsync().catch(console.error);
        recaptchaRef.current.reset();
        await resetPassword({variables: {
            email: data.getEmailForToken,
                newPassword: password.trim(),
                resetPasswordToken: token
            },
            context: {
                headers: {
                    'X-ReCaptcha': recaptchaToken
                }
            }
        })
          .then(() => showAddToCartPopupMessage(t('login.resetSuccess'), 'success', 4000))
          .catch((e) => showAddToCartPopupMessage(e.message, 'error', 4000))
        history.push("/customer/account")
    }

    return <Fragment>
        <div className="new-password">
            <div className="new-password-container">
                <form className="new-password-form" onSubmit={handleSubmit(onSubmit)}>
                    <ReCAPTCHA sitekey={configData && configData.configProvider.captchaWebsiteKey} ref={recaptchaRef} size="invisible" />
                    <h1 className="new-password-form__title"><T id="edit.setPassword" /></h1>
                    <I18n>
                        {t => (
                            <>
                    <fieldset className="fieldset" data-hasrequired="* Obvezna polja">
                        <div className="field password required">
                            <div className="control">
                                <FormInput
                                    register={register}
                                    errors={errors}
                                    text="Lozinka"
                                    type="password"
                                    name="password"
                                    refProp={register({
                                        required: true,
                                        minLength: {
                                            value: 8,
                                            message: t("edit.passwordError"),
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="field confirmation required">
                            <div className="control">
                                <FormInput
                                    register={register}
                                    errors={errors}
                                    text="Ponovite lozinku"
                                    type="password"
                                    name="repeatPassword"
                                    refProp={register({
                                        required: true,
                                        validate: value => (value === getValues()['password'] ? true : t("edit.repeatPasswordsError")),
                                    })}
                                />
                            </div>
                        </div>
                    </fieldset>
                            </>
                        )}
                        </I18n>
                    <div className="actions-toolbar">
                        <div className="primary">
                            <button type="submit" className="btn btn_hover-submit sidebar-add-product-submit"><span><T id="edit.setPassword" /></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Fragment>
};

export default ResetPassword;