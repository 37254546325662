import React, { Fragment, useContext, useState } from 'react';
import {useMutation} from "@apollo/react-hooks";
import {useForm} from "react-hook-form";
import {useHistory, Link} from "react-router-dom";
import {REQUEST_RESET} from "./queriesPasswordReset";
import FormInput from "../../../components/UI/FormInput/FormInput";
import { I18nContext, T } from '@deity-io/falcon-i18n';
import { usePopup } from '../../../context/PopupContext';
import './RequestReset.scss';

const RequestReset = () => {
    const { register, handleSubmit, errors } = useForm();
    const [requestPasswordResetEmail, {error}] = useMutation(REQUEST_RESET);
    const history = useHistory();
    const { t } = useContext(I18nContext);
    const { showAddToCartPopupMessage } = usePopup();

    const onSubmit = async submitData => {
        const {email} = submitData;
        await requestPasswordResetEmail({variables: {email}})
          .then(() => showAddToCartPopupMessage(t('login.requestSuccess', {email}), 'success', 4000))
          .catch((e) => showAddToCartPopupMessage(e.message, 'error', 4000));
        history.push("/customer/account")
    }

    return <Fragment>
        <div className="forgot">
            <div className="forgot-container">
                <form className="forgot-form" onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="forgot-form__title"><T id="login.forgotPassword" /></h1>
                    <fieldset className="fieldset" data-hasrequired="* Obvezna polja">
                        <div className="field note">
                            <span><T id="login.requestReset" /></span>
                        </div>
                        <div>
                            <div className="control">
                                <FormInput
                                    register={register}
                                    errors={errors}
                                    text="Email"
                                    type="text"
                                    name="email"
                                    refProp={register({
                                        required: true
                                    })}
                                />
                                {error && <p className="not-valid"><T id="login.emailExample" /></p>}
                            </div>
                        </div>
                    </fieldset>
                    <div className="actions-toolbar">
                        <div className="primary">
                            <button type="submit" className="btn btn_hover-submit sidebar-add-product-submit forgot-form__button"><span><T id="login.resetPassword" /></span>
                            </button>
                        </div>
                        <div className="secondary">
                            <Link to="/customer/account"><T id="address.back" /></Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Fragment>
}

export default RequestReset;